<template>
  <div>
    <button-top
      v-if="permissions.u"
      icon="EditIcon"
      tooltip="Editar usuario"
      name="Editar"
      :date="form.created_at"
      @action="$router.push(`/usuarios/update/${form.id}`)"
    ></button-top>

    <b-row>
      <b-col cols="3" class="font-weight-bold">Nombre</b-col>
      <b-col cols="3">{{ form.name }}</b-col>
      <b-col cols="3" class="font-weight-bold">Apellidos</b-col>
      <b-col cols="3">{{ form.last_name }}</b-col>
    </b-row>

    <b-row class="mt-1">
      <b-col cols="3" class="font-weight-bold">Tipo documento</b-col>
      <b-col cols="3">{{ form.type_document_name }}</b-col>
      <b-col cols="3" class="font-weight-bold">Numero documento</b-col>
      <b-col cols="3">{{ form.document_number }}</b-col>
    </b-row>

    <b-row class="mt-1">
      <b-col cols="3" class="font-weight-bold">Email</b-col>
      <b-col cols="3">{{ form.email }}</b-col>
      <b-col cols="3" class="font-weight-bold">Movil</b-col>
      <b-col cols="3">{{ form.mobile }}</b-col>
    </b-row>

    <b-row class="mt-1">
      <b-col cols="3" class="font-weight-bold">Cargo</b-col>
      <b-col cols="3">{{ form.position }}</b-col>
      <b-col cols="3" class="font-weight-bold">Rol</b-col>
      <b-col cols="3">{{ form.profile_name }}</b-col>
    </b-row>

    <b-row class="mt-1">
      <b-col cols="3" class="font-weight-bold">Tercero</b-col>
      <b-col cols="3">{{ form.third_name == null ? 'n/a' : form.third_name }}</b-col>
      <b-col cols="3" class="font-weight-bold">Pais</b-col>
      <b-col cols="3">{{ form.country_name }}</b-col>
    </b-row>
    <b-row class="mt-1">
      <b-col cols="3" class="font-weight-bold">Departamento</b-col>
      <b-col cols="3">{{ form.department_name }}</b-col>
      <b-col cols="3" class="font-weight-bold">Municipio</b-col>
      <b-col cols="3">{{ form.city_name }}</b-col>
    </b-row>
    <b-row class="mt-1">
      <b-col cols="3" class="font-weight-bold">Estado</b-col>
      <b-col cols="3">{{ form.state == 1 ? "Activo" : "Inactivo" }}</b-col>
    </b-row>
  </div>
</template>
<script>
export default {
  data() {
    return {
      form: {
        id: "",
        name: "",
        last_name: "",
        type_document_name: "",
        document_number: "",
        email: "",
        mobile: "",
        password: "",
        position: "",
        profile_name: "",
        third_name: "",
        country_name: "",
        department_name: "",
        city_name: "",
        state: "",
      },
    };
  },
  mounted() {
    this.init();
  },

  methods: {
    async init() {
      this.form.id = this.$route.params.id;
      await this.getPermission();
      await this.get();
    },

    async get() {
      try {
        this.loading();
        this.form = (
          await this.$http.get(`administration/user/show/${this.form.id}`)
        ).data.data;
        this.setHeaderTitle(`${this.form.document_number} / ${this.form.name}`);
      } catch (err) {
        if (err.response.status === 404) {
          this.$router.push({ name: "usuarios" });
        }
        this.notify("Error", err.response.data.message, "danger");
      } finally {
        this.not_loading();
      }
    },
  },
};
</script>